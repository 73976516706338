import React, { useEffect } from 'react';
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
} from '@ublend-npm/aulaui-next';
import PropTypes from 'prop-types';
import { setAPIBlock } from '@core/api/setBlockApi';

const ActiveDialogBox = ({
  open,
  handleRegenerateSessionToken,
  handleSessionLogout,
}) => {
  useEffect(() => {
    if (open) {
      setAPIBlock(true);
    } else {
      setAPIBlock(false);
    }
  }, [open]);

  const handleYesClick = async () => {
    setAPIBlock(false);
    await handleRegenerateSessionToken();
  };

  const handleNoClick = () => {
    setAPIBlock(false);
    handleSessionLogout();
  };

  const buttonActions = {
    primary: {
      label: 'Yes',
      onClick: handleYesClick,
    },
    secondary: {
      label: 'No',
      onClick: handleNoClick,
    },
  };

  const displayContent =
    "Please click 'Yes' to stay signed in. If you select 'No,' you will be logged out.";

  const displayTitle = 'Are you still using Aula?';
  return (
    <Dialog open={open} width="600" height="auto">
      <DialogTitle closeIcon={false}>{displayTitle}</DialogTitle>
      <DialogContent>{displayContent}</DialogContent>
      <DialogActions
        primary={buttonActions.primary}
        secondary={buttonActions.secondary}
        border={false}
      />
    </Dialog>
  );
};

ActiveDialogBox.propTypes = {
  open: PropTypes.bool.isRequired,
  handleRegenerateSessionToken: PropTypes.func.isRequired,
  handleSessionLogout: PropTypes.func.isRequired,
};

export default ActiveDialogBox;
