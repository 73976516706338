/* eslint-env browser */
import 'babel-polyfill';
import { useIdleTimer } from 'react-idle-timer';
import PropTypes from 'prop-types';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import regenerateSessionToken from '@core/api/users/regenerateSessionToken';

import { Provider as AulaNextThemeProvider } from '@ublend-npm/aulaui-next';
import React, { useState, useEffect } from 'react';
import { StyleRoot } from 'radium';
import { Provider } from 'react-redux';
import { Router, Route, IndexRedirect, hashHistory } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';
import { configure as configureHotkeys } from 'react-hotkeys';
import { QueryClient, QueryClientProvider } from 'react-query';

import { createCookie, getCookie } from '@core/utils/cookie';

import AulaDevTools from './components/containers/common/AulaDevTools';
import App from './components/presentational/App.container';
import DashboardRoute from './routes/Dashboard';
import LogoutRoute from './routes/Logout';
import FilesRoute from './routes/Files';
import { loginWithSecureAuthCookie } from '../core/actions/user/login';
import {
  analyticsTrackEvent,
  init as analyticsInit,
} from '../core/utils/analytics';
import { getQueryString } from '../core/utils/queryParams';
import * as analyticsTypes from '../core/constants/analytics';
import Raven from '../core/utils/raven';
import Platform from '../core/utils/platform';
import './fonts/font.css';
import redirectToGeneral from './utils/redirectToGeneral';
import logout from './utils/logout';
import addAxiosInterceptors from './utils/addAxiosInterceptors';
import ActiveDialogBox from './components/containers/ActiveDialogBox';

configureHotkeys({
  ignoreEventsCondition: () => false,
});

const TWENTY_FIVE_MINUTES_INTERVAL = 25 * 60 * 1000;
const THIRTY_MINUTES_INTERVAL = 30 * 60 * 1000;
const THIRTY_SECONDS_INTERVAL = 30 * 1000; // 30 seconds
const DEBOUNCE_TIMER = 500;

class UserAuthError extends Error {
  constructor(message) {
    super(message);
    this.name = 'UserAuthError';
  }
}

const loadApp = async (store, institutionData, regenerateFunc) => {
  if (regenerateFunc) await regenerateFunc();
  const user = await store.dispatch(loginWithSecureAuthCookie());

  if (!user || user.error) {
    throw new UserAuthError(user.error);
  }

  await analyticsInit(store.getState);
  const loginEvent = getQueryString('login');
  if (loginEvent === 'true') {
    analyticsTrackEvent(analyticsTypes.CODE_LOGIN);
  } else {
    analyticsTrackEvent(analyticsTypes.LAUNCH_AULA);
  }

  Raven.configureScope((scope) => {
    if (
      store.getState() &&
      store.getState().user &&
      store.getState().user.user
    ) {
      scope.setUser({ id: store.getState().user.user.id });
    }
  });

  Raven.setTags({
    institution: institutionData.general.institution,
    Platform,
  });
};

const WebApp = (props) => {
  const { store, institutionData } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [history, setHistory] = useState();
  const [isSessionTimedout, setIsSessionTimedout] = useState(false);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });
  const ldClient = useLDClient();
  const allFlags = ldClient?.allFlags();

  const { institution } = institutionData.general;

  const LATEST_ACTIVITY_COOKIE = `${institution}-latest-activity-timestamp`;
  const SESSION_STATUS_COOKIE = `${institution}-session-status`;

  const setSessionStatusCookie = (value) => {
    createCookie(
      SESSION_STATUS_COOKIE,
      JSON.stringify(value),
      null,
      '/',
      'aula.education'
    );
  };

  const setLatestActivityCookie = () => {
    const value = new Date().getTime();
    createCookie(LATEST_ACTIVITY_COOKIE, value, null, '/', 'aula.education');
  };

  const handleSessionLogout = async () => {
    await logout({ returnAfterLogin: true });
    setIsSessionTimedout(false);
    setSessionStatusCookie({ sessionStatus: false, loggedOut: true });
  };

  const handleRegenerateSessionToken = async () => {
    try {
      // regenerate session token api
      if (!isSessionTimedout) {
        await regenerateSessionToken();
      }
    } catch (error) {
      if (error.status === 401) {
        setIsSessionTimedout(true);
      }
    }
  };

  const onLoadSuccess = () => {
    setHistory(syncHistoryWithStore(hashHistory, store));
    setIsLoading(false);
    addAxiosInterceptors();
    if (allFlags && allFlags['ft-session-token-refresh-enabled-202412']) {
      setLatestActivityCookie();
      setSessionStatusCookie({ sessionStatus: true, loggedOut: false });
    }
  };

  useEffect(() => {
    let regenerateFunc;
    const sessionTokenEnabled =
      allFlags && allFlags['ft-session-token-refresh-enabled-202412'];
    if (sessionTokenEnabled) {
      regenerateFunc = handleRegenerateSessionToken;
    }

    loadApp(store, institutionData, regenerateFunc)
      .then(onLoadSuccess)
      .catch((error) => {
        Raven.captureException(error, {
          tags: {
            context: 'loadApp',
          },
        });

        if (error.name === 'UserAuthError') {
          redirectToGeneral({ returnAfterLogin: true });
          return;
        }

        onLoadSuccess();
      });
    if (sessionTokenEnabled) {
      const interval = setInterval(() => {
        // check wether is the app still loading and also the app has not been idle for 30 minutes to regenerate session token
        handleRegenerateSessionToken();
      }, TWENTY_FIVE_MINUTES_INTERVAL);

      return () => clearInterval(interval);
    }
    return () => {};
  }, []);

  const handleOnIdle = async () => {
    const sessionStatus = JSON.parse(
      unescape(getCookie(SESSION_STATUS_COOKIE))
    );
    if (!sessionStatus.sessionStatus) {
      setIsSessionTimedout(true);
      if (sessionStatus.loggedOut) handleSessionLogout();
    } else {
      const isValidSession =
        new Date().getTime() - Number(getCookie(LATEST_ACTIVITY_COOKIE)) <
        THIRTY_MINUTES_INTERVAL;
      if (!isValidSession) {
        setIsSessionTimedout(true);
        setSessionStatusCookie({ sessionStatus: false, loggedOut: false });
      } else {
        setIsSessionTimedout(false);
      }
    }
  };

  useIdleTimer({
    onAction: setLatestActivityCookie,
    debounce: DEBOUNCE_TIMER,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      if (allFlags && allFlags['ft-session-token-refresh-enabled-202412']) {
        handleOnIdle();
      } else {
        clearInterval(interval);
      }
    }, THIRTY_SECONDS_INTERVAL);
    return () => {
      clearInterval(interval);
    };
  }, [allFlags]);

  if (isLoading) {
    return null;
  }

  const regenerateSessionTokenForDialogBox = async () => {
    await regenerateSessionToken();
    setIsSessionTimedout(false);
    setSessionStatusCookie({ sessionStatus: true, loggedOut: false });
  };

  return (
    <>
      <AulaNextThemeProvider>
        <StyleRoot>
          <ActiveDialogBox
            open={isSessionTimedout}
            handleRegenerateSessionToken={regenerateSessionTokenForDialogBox}
            handleSessionLogout={handleSessionLogout}
          />
          <Provider store={store}>
            <QueryClientProvider client={queryClient}>
              <Router history={history}>
                <Route path="/" component={App}>
                  {LogoutRoute}
                  {DashboardRoute(queryClient, ldClient)}
                  {FilesRoute}
                  <IndexRedirect to="dashboard" />
                </Route>
              </Router>
            </QueryClientProvider>
          </Provider>
        </StyleRoot>
        <AulaDevTools />
      </AulaNextThemeProvider>
    </>
  );
};

WebApp.propTypes = {
  store: PropTypes.shape().isRequired,
  institutionData: PropTypes.shape().isRequired,
};

export default WebApp;
