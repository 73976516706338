/* eslint-disable no-console */
import axios from 'axios';
import getAnalyticsHeaders from '../../utils/analyticsHeaders';

let blockRequests = false;

const request = async (accessToken, config) => {
  if (blockRequests) {
    console.warn('API call blocked:', config.url);
    return { data: null, status: null };
  }
  const headers = {
    ...config.headers,
    Authorization: `Bearer ${accessToken}`,
    ...getAnalyticsHeaders(),
  };

  const response = await axios({ ...config, headers });
  return response;
};

export const setAPIBlockGroups = (shouldBlock) => {
  blockRequests = shouldBlock;
  console.log(`API Blocking is now ${shouldBlock ? 'ENABLED' : 'DISABLED'}`);
};

export default request;
