/* eslint-disable no-console */
import axios from 'axios';
import { attach } from 'retry-axios';
import { getCurrentUser } from '../../../utils/currentUser';
import getAnalyticsHeaders from '../../../utils/analyticsHeaders';

const retryOnTimedoutRequest = ({ code }) => code === 'ECONNABORTED';

let blockRequests = false;

/* The following is mostly pasted from retry-axios's README */
const buildAxiosInstance = () => {
  const axiosInstance = axios.create();

  axiosInstance.defaults = {
    ...axiosInstance.defaults,
    raxConfig: {
      instance: axiosInstance,
      shouldRetry: retryOnTimedoutRequest,
    },
  };

  attach(axiosInstance);
  return axiosInstance;
};

const getCredentialHeaders = () => {
  const user = getCurrentUser();
  return {
    'x-user-id': user.id,
  };
};

const request = async (config) => {
  if (blockRequests) {
    console.warn('API call blocked:', config.url);
    return null;
  }
  const headers = {
    ...config.headers,
    ...getCredentialHeaders(),
    ...getAnalyticsHeaders(),
  };

  const axiosInstance = buildAxiosInstance();
  const response = await axiosInstance({
    ...config,
    withCredentials: true,
    headers,
  });
  return response;
};

export const setAPIBlockConversations = (shouldBlock) => {
  blockRequests = shouldBlock;
  console.log(`API Blocking is now ${shouldBlock ? 'ENABLED' : 'DISABLED'}`);
};

export default request;
