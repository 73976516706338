import axios from 'axios';
import getAnalyticsHeaders from '@core/utils/analyticsHeaders';
import { REGEN_SESSION_TOKEN } from '../../constants/endpoints';

const regenerateSessionToken = () => {
  return axios({
    method: 'post',
    url: REGEN_SESSION_TOKEN(),
    withCredentials: true,
    headers: {
      ...getAnalyticsHeaders(),
    },
  });
};

export default regenerateSessionToken;
