import axios, { AxiosResponse, AxiosRequestConfig } from 'axios';
import { getCurrentUser } from '../../utils/currentUser';
import getAnalyticsHeaders from '../../utils/analyticsHeaders';
import { EndpointContract } from '@ublend-npm/aula-schema';

interface MaterialsRequestHeaders {
  'x-user-id': string;
}

const getCredentialHeaders = (): MaterialsRequestHeaders => {
  const user = getCurrentUser();
  return {
    'x-user-id': user.id,
  };
};

let blockRequests = false;

export const getData = <T>(response: AxiosResponse<T>) => response.data;

const request = async <T extends EndpointContract = EndpointContract>(
  config: AxiosRequestConfig<T['body']>
) => {
  if (blockRequests) {
    console.warn('API call blocked:', config.url);
    return null as never;
  }
  const response = await axios<T['responseBody']>({
    ...config,
    withCredentials: true,
    headers: {
      ...config.headers,
      ...getCredentialHeaders(),
      ...getAnalyticsHeaders(),
    },
  });

  return response;
};

export const setAPIBlockMaterials = (shouldBlock) => {
  blockRequests = shouldBlock;
  console.log(`API Blocking is now ${shouldBlock ? 'ENABLED' : 'DISABLED'}`);
};

export default request;
