import { setAPIBlockConversations } from '@core/conversations/api/conversations/request';
import { setAPIBlockGroups } from '@core/groups/api/request';
import { setAPIBlockMaterials } from '@core/materials/api/request';
import { setAPIBlockCore } from './request';

// // Function to toggle API blocking
export const setAPIBlock = (shouldBlock) => {
  const apiBlockFunctions = [
    setAPIBlockConversations,
    setAPIBlockCore,
    setAPIBlockGroups,
    setAPIBlockMaterials,
  ];

  apiBlockFunctions.forEach((blockFunction) => blockFunction(shouldBlock));
};
